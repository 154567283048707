
import {
    Component,
    Vue,
	Watch
} from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { getNameByEmail } from '@/utils/users';
import { DateTime } from 'luxon';
import {
	getComponent,
	addNumberPadding,
	sleep
} from '@/utils/helpers'
import {
    SCHEDULE_COMPONENT_COLORS,
} from '@/lib/constants'
import workspaceModule from '@/store/modules/workspaceModule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		ComponentDetails: () => getComponent('schedule/ComponentDetails'),
		AddJob: () => getComponent('schedule/AddJob'),
		AddField: () => getComponent('schedule/AddField'),
		EditScheduleGroup: () => getComponent('schedule/EditScheduleGroup'),
		WorkflowAutomationDialog: () => getComponent('tasqs/WorkflowAutomationDialog'),
		Multiselect,
    },
})

export default class Calendar extends mixins() {

	calendarWeeks: any[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

	calendarComponents: any[] = []

	filterScheduledRowComponents: any[] = []

	dates: any[] = []

	didHoverCalendarComponent = ''

	currentDate = ''

	savingDetailsBannerIDText = ''

	refreshComponentKey = 0

	popupBackground = false
	addNewJobPopupInnerShowing = false
	addNewWorkspacePopupAddField = false

	viewTotalDays = 30

	loadingGanttChart = false

	firstDayOnCalendar
	lastDayOnCalendar

	isDeletingFile = false

	scheduleComponentUserColors = {}

	workflowAutomationDialog = false

	savingChartComponentChange = false

	showSaveSuccessfulNotification = false
	showSavingDetailsBannerID = false

	existingTableID = ''

	hideAddNewJobClicked() {
		this.addNewJobPopupInnerShowing = false
		this.popupBackground = false

	}

	closeEditGroupDetailsPopup() {
		this.popupBackground = false
		scheduleModule.setEditGroupDetails(null)
	}


	closeWorkflowAutomationDialog() {
		this.workflowAutomationDialog = false
	}

	get editGroupDetails() {
		return scheduleModule.editGroupDetails
	}

	get expandedFile() {
		return scheduleModule.expandedFile
	}

	get activeComponent() {
		return scheduleModule.activeComponent
	}

	get confirmFileDelete() {
		return scheduleModule.confirmDeleteFile
	}

	get activeColumns() {
		return scheduleModule.activeComponentResponseColumns
	}

	async confirmDeleteFile() {
		this.isDeletingFile = true
		if (scheduleModule.selectedTabID == null) {
			this.cancelDeleteFile()
			return
		}

		var editingColumn: any = null

		for (var y = 0; y < scheduleModule.activeComponent.Columns.length; y++) {
			if (scheduleModule.activeComponent.Columns[y].CustomID == scheduleModule.selectedTabID) {
				editingColumn = y
			}
		}

		var updateColumn = JSON.parse(JSON.stringify(scheduleModule.activeComponent.Columns[editingColumn]))
		var spliceAtIndex: any = null


		for (var x = 0; x < updateColumn.Response.length; x++) {
			if (updateColumn.Response[x].FileName == this.confirmFileDelete.FileName) {
				spliceAtIndex = x
			}
		}

		updateColumn.Response.splice(spliceAtIndex, 1);
		
		scheduleModule.updateColumnByIndex({
			column_index: editingColumn, 
			column: updateColumn})
		await scheduleModule.updateScheduleItem({
			item_id: this.activeComponent.ID,
			columns: JSON.stringify(this.activeColumns)
		})

		this.isDeletingFile = false
		this.cancelDeleteFile()
		this.refreshComponentKey += 1
		// scheduleModule.setConfirmDeleteFile(null)

	}



	updateComponentDetails() {
		this.refreshComponentKey += 1
	}


	getFullComponentDetailsByID(id) {
		// @ts-ignore
		for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
			// @ts-ignore
			for (var y = 0; y < workspaceModule.activeTable.Groups[x].Items.length; y++) {
				// @ts-ignore
				if (id == workspaceModule.activeTable.Groups[x].Items[y].ID) {
					// @ts-ignore
					return workspaceModule.activeTable.Groups[x].Items[y]
				}
			}
		}
	}

	getComponentStatus(component) {
		var detailedComponent = this.getFullComponentDetailsByID(component.component_id)
		if (detailedComponent != null) {
			if (detailedComponent.Status == "New") {
				return ''
			} else if (detailedComponent.Status == "In Progress") {
				return ''
			} else if (detailedComponent.Status == "Waiting On") {
				return ' opacity: 0.5;'
			} else if (detailedComponent.Status == "Done") {
				return ' background: rgba(144,144,144,1);'
			}
			// return detailedComponent.Status
		}
		return ''
	}

	showComponentDetails(component) {
		scheduleModule.setActiveComponent(null)
		// var detailedComponent = this.getFullComponentDetailsByID(component.component_id)
		// if (detailedComponent != null) {
			scheduleModule.setActiveComponent(component)
			for (var x = 0; x < component.Columns.length; x++) {
				component.Columns[x].ItemID = component.ID
			}
			scheduleModule.setActiveComponentResponseColumns(component.Columns)
		// }
		this.refreshComponentKey += 1

	}



	updateComponentRanges(componentsToUpdate) {
		
		this.setSavingDetailsBannerID("Saving updated schedule")
		// document.getElementById("savingDetailsBannerID") !.style.display = "block"
		var promises: any[] = [];
		for (var w = 0; w < componentsToUpdate.length; w++) {
			var draggedComponentStartDate = componentsToUpdate[w].StartDate.toISOString().slice(0,10).replace(/-/g,"-");
			var draggedComponentEndDate = componentsToUpdate[w].EndDate.toISOString().slice(0,10).replace(/-/g,"-");

			// TASQ_DATE_RANGE
			var localComponent = this.getFullComponentDetailsByID(componentsToUpdate[w].PredictionID)
			if (!localComponent) {
				continue
			}
			for (var e = 0; e < localComponent.Columns.length; e++) {
				if (localComponent.Columns[e].ColumnType == "START_DATE") {
					localComponent.Columns[e].Response = draggedComponentStartDate
				}
			}
			for (var e = 0; e < localComponent.Columns.length; e++) {
				if (localComponent.Columns[e].ColumnType == "DUE_DATE") {
					localComponent.Columns[e].Response = draggedComponentEndDate
				}
			}
			promises.push(scheduleModule.updateScheduleItem({
				item_id: componentsToUpdate[w].PredictionID,
				group_id: componentsToUpdate[w].RowID.split("<>")[1],
				columns: JSON.stringify(localComponent.Columns)
			}))
		}

		Promise.all(promises).then(() => {
			this.savingChartComponentChange = false
			
			this.addDataToGanttChart()
			this.removeSavingDetailsBannerID(5000, "Your changes have been saved")
		}, function (err) {
			// error occurred
		});

	}

	async updateSingleItemDateRange(start_date, end_date, item_id) {
		this.setSavingDetailsBannerID("Updating item date range")
			// TASQ_DATE_RANGE
			var localComponent = this.getFullComponentDetailsByID(item_id)
			if (!localComponent) {
				return
			}
			for (var e = 0; e < localComponent.Columns.length; e++) {
				if (localComponent.Columns[e].ColumnType == "TASQ_DATE_RANGE") {
					localComponent.Columns[e].Response[0] = start_date
					localComponent.Columns[e].Response[1] = end_date
				}
			}
		await scheduleModule.updateScheduleItem({
			item_id: item_id,
			columns: JSON.stringify(localComponent.Columns)
		})

		this.savingChartComponentChange = false
		
		this.addDataToGanttChart()
		this.removeSavingDetailsBannerID(5000, "Your changes have been saved")

	}



	async removeSavingDetailsBannerID(wait = 5000, text = '') {
		this.savingChartComponentChange = false
		if (text != '') {
			this.setSavingDetailsBannerIDText(text)
		}
		await sleep(wait)
		this.showSavingDetailsBannerID = false
		this.savingDetailsBannerIDText = ''
	}


	setSavingDetailsBannerID(text) {
		this.savingChartComponentChange = true
		this.showSavingDetailsBannerID = true
		this.setSavingDetailsBannerIDText(text)
	}

	setSavingDetailsBannerIDText(text) {
		this.savingDetailsBannerIDText = text
	}

	cancelDeleteFile() {
		scheduleModule.setConfirmDeleteFile(null)
	}



	getBackgroundColorComponentVal(component) {
		var color = "#51cea8"
		if (this.scheduleComponentUserColors[component.ID] != null) {
			color = this.scheduleComponentUserColors[component.ID]
		} else {
			color = SCHEDULE_COMPONENT_COLORS[Object.keys(this.scheduleComponentUserColors).length % SCHEDULE_COMPONENT_COLORS.length]
			this.scheduleComponentUserColors[component.ID] = color
		}
		return 'color: rgba(255,255,255,0.9); background: ' + color + ';'
	}


	getWellName(component) {
		for (var x = 0; x < component.Columns.length; x++) {
			if (component.Columns[x]["ColumnType"] == "WELL" && component.Columns[x]["CoreField"]) {
				if (component.Columns[x]["Response"]["value"] != null) {
					return component.Columns[x]["Response"]["text"]
				}
				return component.Columns[x]["Response"]
			}
		}
	}

	didSelectViewType(view) {
		this.$emit('did-select-view', view);
	}

	datediff(first, second) {
		// Take the difference between the dates and divide by milliseconds per day.
		// Round to nearest whole number to deal with DST.
		return Math.round((second-first)/(1000*60*60*24));
	}


	getWidthComponentVal(row_val, component) {
		var rowStartDate = new Date(component["FromDate"])
		var rowEndDate = new Date(component["ToDate"])

		rowStartDate.setDate(rowStartDate.getDate() + 1)
		rowEndDate.setDate(rowEndDate.getDate() + 1)




		var style_string = ''
		for (var x = 0; x <= this.datediff(rowStartDate, rowEndDate); x++) {
			if (x > 0) {
				style_string += ' + '
			}
			style_string += '14.28%'
		}
		return 'width: calc(' +  style_string + ');'
	}


	getLeftComponentVal(row_val, component) {

		var rowStartDate = new Date(row_val["start_date"])
		var componentStartDate = new Date(component["FromDate"])

		rowStartDate.setDate(rowStartDate.getDate() + 1)
		componentStartDate.setDate(componentStartDate.getDate() + 1)



		var style_string = ''
		for (var x = 0; x < this.datediff(rowStartDate, componentStartDate); x++) {
			if (x > 0 && x != (this.datediff(rowStartDate, componentStartDate))) {
				style_string += ' + '
			}
			style_string += '14.28%'
		}
		return 'left: calc(' +  style_string + ');'



	}


    get scheduledRowComponents(): any[] {
        return scheduleModule.scheduledRowComponents
    }




	getHeightForMainRow(scheduledRowComponent, subtract = null) {
		// for (var x = 0; x < scheduledRowComponent.rows.length; x++) {
		// 	scheduledRowComponent.rows[x]
		// }

		// @ts-ignore
		if (subtract != null) {
			// @ts-ignore
			return ((Object.keys(scheduledRowComponent.rows).length * 35) + subtract).toString()
		} else {
			// @ts-ignore
			return (Object.keys(scheduledRowComponent.rows).length * 35).toString()
		}
		
	}

	getCalendarRowComponents(scheduledRowComponent) {
		var startDate = new Date(scheduledRowComponent["start_date"])
		var endDate = new Date(scheduledRowComponent["end_date"])
		startDate.setDate(startDate.getDate() + 1)
		endDate.setDate(endDate.getDate() + 1)
		var components: any[] = []
		for (var d = startDate; d <= endDate; startDate.setDate(startDate.getDate() + 1)) {
			components.push((new Date(d)).toISOString().substring(0, 10));
		}
		return components

	}

    async created() {
		this.currentDate = addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
		scheduleModule.getColumnMappingData({})
		scheduleModule.resetScheduledRowComponents()
		accountModule.getReassignmentList({useExisting: true});

		// await workspaceModule.getWorkspaces()
		this.initializePage()
		

    }

	initializePage() {
		this.existingTableID = this.$route.params.table_id
		this.reloadPage()

	}



	async reloadPage() {


		this.calendarComponents = []
		var date = new Date();
		var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
		var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		this.firstDayOnCalendar = new Date(date.getFullYear(), date.getMonth(), 1);
		var firstDayOfMonthWeekday = firstDayOfMonth.getDay() // 0 = Sunday
		var lastDayOfMonthWeekday = lastDayOfMonth.getDay() // 0 = Sunday

		this.firstDayOnCalendar.setDate(this.firstDayOnCalendar.getDate() - firstDayOfMonthWeekday)
		var lastDayOnCalendarAddition = 6 - lastDayOfMonthWeekday

		lastDayOfMonth.setDate(lastDayOfMonth.getDate() + lastDayOnCalendarAddition)
		this.lastDayOnCalendar = lastDayOfMonth


		
		var currentCalendarDate = new Date(this.firstDayOnCalendar.getTime());
		while (currentCalendarDate <= this.lastDayOnCalendar) {
			this.calendarComponents.push(new Date(currentCalendarDate.getTime()))
			currentCalendarDate.setDate(currentCalendarDate.getDate() + 1)
			
		}



		this.loadingGanttChart = true

		scheduleModule.resetScheduledRowComponents()
		accountModule.getReassignmentList({useExisting: true});

		await this.addDataToGanttChart()
		this.loadingGanttChart = false

	}



	async addDataToGanttChart() {
		this.currentDate = addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()


		// var end_datetime = new Date(new Date().setHours(this.firstDayOnCalendar.getHours() + (this.viewTotalDays * 24)))

		const startDateString = `${this.firstDayOnCalendar.getUTCFullYear()}-${addNumberPadding(this.firstDayOnCalendar.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(this.firstDayOnCalendar.getUTCDate(), 2, '0')}T${addNumberPadding(this.firstDayOnCalendar.getUTCHours(), 2, '0')}:${addNumberPadding(this.firstDayOnCalendar.getUTCMinutes(), 2, '0')}:${addNumberPadding(this.firstDayOnCalendar.getUTCSeconds(), 2, '0')}`;
		const endDateString = `${this.lastDayOnCalendar.getUTCFullYear()}-${addNumberPadding(this.lastDayOnCalendar.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(this.lastDayOnCalendar.getUTCDate(), 2, '0')}T${addNumberPadding(this.lastDayOnCalendar.getUTCHours(), 2, '0')}:${addNumberPadding(this.lastDayOnCalendar.getUTCMinutes(), 2, '0')}:${addNumberPadding(this.lastDayOnCalendar.getUTCSeconds(), 2, '0')}`;
		
		// @ts-ignore
		await scheduleModule.getScheduledJobsV2({ start_date: startDateString, end_date: endDateString, table_id: workspaceModule.activeTable.ID, schedule_type: "Calendar" });
		this.filterScheduledRowComponents = []
		// await sleep(100)
		this.dates = []
		this.filterScheduledRowComponents = []

		var baseStartDate = new Date(this.firstDayOnCalendar.getTime());
		// Show chart 30 days
		for (var days = 0; days < this.viewTotalDays; days++) {

			var day_datetime = new Date(baseStartDate.getTime());
			// var day_datetime = new Date(this.firstDayOnCalendar)
			day_datetime = new Date(day_datetime.setDate(baseStartDate.getDate() + days))
			var hours_array: Date[] = []
			var day_object = {
				"day_id": day_datetime.toString(),
				"day_short": day_datetime.getDate(),
				"day": day_datetime,
				"hours": hours_array
			}
			for (var hours = 0; hours < 24; hours++) {
				var hour_datetime = new Date(day_datetime)
				hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours))
				day_object.hours.push(hour_datetime)
			}
			this.dates.push(day_object)
		}

	}





	get automationRuleCount() {
		if (workspaceModule.activeTable == null) {
			return 0
		}
		// @ts-ignore
		return workspaceModule.activeTable.Rules.length
	}


	didHoverOverCalendarComponent(calendarComponent) {
		this.didHoverCalendarComponent = calendarComponent
	}

	didLeaveHoverOverCalendarComponent() {
		this.didHoverCalendarComponent = ''
	}

		addNewJobClicked() {
		this.addNewJobPopupInnerShowing = true
		this.popupBackground = true

	}


	sameDay(d1, d2) {
		return d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate();
	}

	dateIsToday(dateString) {
		if (this.sameDay((new Date(dateString)), new Date())) {
			return true
		}
		return false
	}

	getDayFromDate(dateString) {
		
		return (new Date(dateString)).getDate()
	}

	daysInMonth(anyDateInMonth) {
		return new Date(anyDateInMonth.getFullYear(), 
						anyDateInMonth.getMonth()+1, 
						0).getDate();

	}


	@Watch('$route.params.table_id', {
		immediate: true,
	})
	async onTasqRouteIdChange(id: string) {
		if (this.existingTableID != id && id != null && id != undefined) {
			this.existingTableID = id
		this.initializePage()
		}
	}

}


